import React from "react"
import { Link } from "gatsby"
import articleStyles from "./articleCard.module.scss"

const ArticleCard = props => (
  <article className={articleStyles.article}>
    <Link to={props.slug}>
      <div className={articleStyles.article__imageContainer}>
        <img src={props.image} alt={props.alt} />
      </div>

      <h3>{props.title}</h3>
    </Link>
  </article>
)

export default ArticleCard
