import React from "react"

import Layout from "../components/layout"

import Card from "../components/card"
import Head from "../components/head"

import indexStyles from "./index.module.scss"
import { useStaticQuery } from "gatsby"

import Serp from "../../static/searchresults.svg"

import Rocket from "../../static/rocket2.svg"
import ArticleCard from "../components/articleCard"

import { VscGraph } from "react-icons/Vsc"
import { GiCycle } from "react-icons/Gi"
import { GoKeyboard } from "react-icons/Go"
import Button from "../components/button"

const IndexPage = () => {
  const featuredPosts = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(limit: 4, filter: { featured: { eq: true } }) {
        edges {
          node {
            blogImage {
              file {
                url
              }
              description
            }
            category
            slug
            title
            seoDescription
          }
        }
      }
    }
  `)

  return (
    <Layout
      title="We make killer ads and fast apps."
      btnText="Get a quote"
      btnLink="/contact"
    >
      <Head title="Home" />
      <main>
        <section>
          <wrapper className={indexStyles.cards}>
            <Card
              title="Web and App Development"
              icon={<GoKeyboard />}
              desc="Custom web development highly optimized for search engines and search engine marketing campaigns. From basic presentational web sites to full eCommerce stores."
            />
            <Card
              title="Google Marketing"
              icon={<VscGraph />}
              desc="Creating and optimizing campaings for Google Ads (ex Adwords) & Bing Ads. We have extensive experience in numerous industries. Let us take your PPC campaings to the highest level."
            />
            <Card
              title="Display and Remarketing"
              icon={<GiCycle />}
              desc="Google network is the best way to reach 80% of internet users. Follow your site visitors with engaging messages, build your brand awareness and affordably increase conversions."
            />
          </wrapper>
        </section>
        <section>
          <wrapper className={indexStyles.ppc}>
            <h2>Google Marketing</h2>
            <div className={indexStyles.ppc__container}>
              <div className={indexStyles.ppc__image}>
                <img src={Serp} alt="Search Engine Results Page" />
              </div>
              <div className={indexStyles.ppc__text}>
                <li>
                  Get new customers <strong>the same day</strong> - immediate
                  results
                </li>
                <li>
                  Precisely target people looking to buy products you sell{" "}
                  <strong>at the exact moment</strong> they plan to buy
                </li>
                <li>
                  Everything about PPC is <strong>measurable</strong>. You can
                  see exactly how much you spent and how much you gained.
                </li>
                <li>
                  You decide how much you spend -{" "}
                  <strong>suitable for all budgets</strong>. You control the
                  amount to spend.
                </li>
                <li>
                  Target customers <strong>nearby or worldwide</strong>
                </li>
                <li>
                  Easily calculate your{" "}
                  <strong>ROI (return on investment)</strong>
                </li>
              </div>
            </div>
          </wrapper>
        </section>
        <section className="black">
          <wrapper className={indexStyles.web}>
            <h2>Ultrafast web sites:</h2>
            <div className={indexStyles.web__container}>
              <div className={indexStyles.web__image}>
                <img src={Rocket} alt="Site Speed Rocket" />
              </div>
              <div className={indexStyles.web__text}>
                <li>
                  Have lower cost per click in Google Ads and other PPC
                  platforms
                </li>
                <li>
                  Rank better on Google and other search engines result pages -
                  easier SEO
                </li>
                <li>Higher user satisfaction - convert visitors to clients</li>
                <li>Easiest way to beat your competition</li>
              </div>
            </div>
          </wrapper>
        </section>
        <section>
          <wrapper className={indexStyles.us}>
            <h2>Why Us?</h2>
            <div className={indexStyles.us__container}>
              <div className={indexStyles.us__text}>
                <li>
                  We make really <strong>fast</strong> and{" "}
                  <strong>optimized</strong> websites with great user
                  experience.
                </li>
                <li>
                  We make data-driven <strong>high performing</strong> Google
                  Ads campaings for all budgets
                </li>
                <li>
                  You are the <strong>owner</strong> of the Google Ads account
                  even in case you stop using our services.
                </li>
                <li>
                  <strong>No hidden</strong> costs{" "}
                </li>
                <li>
                  Cancel anytime - <strong>no contracts</strong>.
                </li>
                <li>
                  Full transparency and <strong>honest</strong> approach
                </li>
              </div>
            </div>
          </wrapper>
        </section>
        <section className="black">
          <wrapper className={indexStyles.cta}>
            <h2>How much does it cost?</h2>
            <Button btnLink="/contact" text="Get a quote" color="white" />
          </wrapper>
        </section>

        <section>
          <wrapper className={indexStyles.articles}>
            <h2>Articles</h2>
            <div className={indexStyles.articles__container}>
              {featuredPosts.allContentfulBlogPost.edges.map(edge => (
                <ArticleCard
                  image={edge.node.blogImage.file.url}
                  alt={edge.node.blogImage.description}
                  slug={`/blog/${edge.node.slug}`}
                  category={edge.node.category}
                  title={edge.node.title}
                  description={edge.node.seoDescription}
                />
              ))}
            </div>
          </wrapper>
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage
