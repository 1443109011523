import React from 'react';
import cardStyles from './card.module.scss'

const Card = (props)=>(
    <div className={cardStyles.card}>
        <i className={cardStyles.card__icon}>{props.icon}</i>
        <h3>{props.title}</h3>
        <p>{props.desc}</p>
    
    </div>

)




export default Card;